@import '../../styles/vars.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: $tablet) {
    gap: 10px;
  }
}

// ====== Inputs ============================================================================================

.multi_input_container {
  padding: 16px 16px 0;
  @media (max-width: $tablet) {
    padding: 10px 10px 0;
  }

  :global(.MuiButtonBase-root) {
    width: 20px;
    height: 20px;
    font-size: 13px;
  }
}

.multi_input {
  align-items: center;
}

.input {
  @media (max-width: $tablet) {
    height: 28px;
    padding-right: 2px;
    > input {
      font-size: 12px !important;
    }
    > fieldset {
      border-width: 1px;
    }
  }
}

.separator_icon {
  min-width: 18px;
  transform: rotate(180deg) translateY(-1px);
}

// ====== Calendar ===========================================================================================

.date_picker {
  min-width: unset;
  @media (max-width: $tablet) {
    padding: 10px 0 !important;
  }

  > div {
    padding: 0 8px;
  }

  :global(.MuiDayCalendar-slideTransition) {
    min-height: 252px;
    min-width: unset;
    @media (max-width: $tablet) {
      min-height: 212px;
    }
  }

  :global(.MuiDateCalendar-root) {
    height: unset;
    width: unset;
  }

  :global(.MuiDayCalendar-weekDayLabel) {
    font-size: 14px !important;
    @media (max-width: $tablet) {
      font-size: 13px !important;
      font-weight: 400;
      width: 29px;
      height: 29px;
    }
  }

  :global(.MuiDateRangeCalendar-container) {
    padding: 0 3px;
  }

  :global(.MuiPickersDay-root) {
    font-size: 14px !important;
    @media (max-width: $tablet) {
      font-size: 13px !important;
    }
    &:focus {
      background-color: #437bff;
      color: #fff;
    }
  }

  :global(.Mui-selected) {
    background-color: #437bff;
    color: #fff;
    &:hover {
      background-color: #437bff;
    }
  }

  :global(.MuiPickersArrowSwitcher-root) {
    padding: 0 3px 8px !important;
    > span {
      color: #141414;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      @media (max-width: $tablet) {
        font-size: 14px !important;
      }
    }
    > button {
      width: 24px;
      height: 24px;
    }
  }

  :global(.MuiDateRangePickerDay-root) {
    @media (max-width: $tablet) {
      height: 33px;
      width: 33px;
      > div {
        height: 33px;
        width: 33px;
        > button {
          color: #141414;
          font-size: 12px;
          font-weight: 400;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  :global(.MuiDateRangePickerDay-rangeIntervalDayHighlight) {
    background: rgba(234, 238, 252, 0.7);
  }

  .date_label {
    color: #141414;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }

  .date_button {
    width: 29px;
    padding: 4px;
    border-radius: 5px;
    background: #dae3ff;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.predefined_options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px 0 8px;
  border-top: 1px solid #e7edf1;
}

.predefined_button {
  min-height: 34px;
  padding: 0 8px;
  border-radius: 8px;
  background-color: #f8f9fb;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 150%;
  transition: all 200ms;

  &[data-isActive='true'] {
    background-color: #eaeefc;
  }
}
