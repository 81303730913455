@import '../../styles/vars.scss';

.dropdown_menu {
  &[data-disabled='true'] {
    pointer-events: none;
    opacity: 0.5;
  }
}

.menu_button {
  @media (max-width: $tablet) {
    font-size: 15px;
    padding: 3px;
    > svg {
      width: 18px;
      height: 18px;
    }
  }
}

.menu_container {
  padding: 4px;
  min-width: 214px;
}

.menu_item {
  padding: 5px 4px;
  min-height: 25px;
}

.icon_container {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover_container {
  padding-right: 10px;
}

.divider {
  height: 0.5px;
  background-color: #eaeaee;
  width: auto;
  margin-top: 3px;
  margin-bottom: 3px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
