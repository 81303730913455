.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 24px;
  height: 21px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #d5e4f2;
  }

  &[data-active='true'] {
    div {
      &:first-child {
        width: 8px;
        margin-right: 4px;
      }

      background-color: #292d32;
    }

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: 5px;
      width: 4px;
      height: 4px;
      background-color: #0851fe;
      border-radius: 50%;
    }
  }
}

.line {
  height: 1px;
  border-radius: 99px;
  background-color: #5e7285;

  &:first-child {
    width: 12px;
  }

  &:nth-child(2) {
    width: 8px;
  }

  &:last-child {
    width: 4px;
  }
}
