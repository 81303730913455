.input {
  width: 100%;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #bcc4e3;
  padding: 0 12px;
  transition: all 200ms;
  outline: none;

  &:focus {
    border-color: #0851fe;
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.3;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.popover {
  width: 222px;
  margin-top: 4px;
  padding: 8px 12px;
}

.container_button {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #bcc4e3;
  padding: 7.5px 12px;
  height: 40px;
  line-height: 1;
  cursor: pointer;
  gap: 8px;

  &[data-disbled='true'] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    border-color: #0851fe;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}
