.spinner_container {
  position: relative;
  display: flex;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #e8f4ff;
  margin-left: 0px;
}

.process_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  background-color: #e8f4ff;
  border-radius: 8px;
  padding: 7px 12px 7px 12px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
}
