@import '../../../styles/vars.scss';

.container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #bcc4e3;
  padding: 7.5px 12px;
  height: 40px;
  line-height: 1;
  cursor: pointer;
  gap: 8px;
  @media (max-width: $tablet) {
    height: 32px;
    padding: 3px 5px 3px 8px;
  }

  &[data-disbled='true'] {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }

  &:hover {
    border-color: #0851fe;
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.menu_wrapper {
  z-index: 1;
  max-width: 416px;
  padding: 0;
}

.list_wrapper {
  display: flex;
  flex-direction: column;
  padding: 0px 8px 0px 8px;
  height: 200px;
  @media (max-width: $tablet) {
    padding: 0px 8px 0px 4px;
  }
}

.menu_container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0px 5px 24px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &[data-is-lazy-processing='true'] {
    &::after {
      opacity: 0.3;
      animation: breathe 0.8s infinite alternate;
    }
  }

  &::after {
    opacity: 0;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.8s;
    border-bottom: 3px solid #7894ff;
  }
}

.search_container {
  padding: 8px;
  border-bottom: 1px solid #efefef;
  margin-bottom: 6px;
}

.input {
  width: 334px;
  flex-wrap: wrap;
  gap: 6px;
  padding: 8px !important;
  @media (max-width: $tablet) {
    padding: 6px 8px !important;
    > input {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      font-size: 13px !important;
    }
  }
}

.options {
  width: 100%;
  max-width: 350px;
  height: 200px;
  overflow: scroll;
}

.option_container {
  font-size: 14px;
  color: #5c668c;
  line-height: 1.5;
  cursor: pointer;
  width: 100%;
  padding: 4px 4px 4px 4px;

  @media (max-width: $tablet) {
    font-size: 13px;
  }

  & > div {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &:hover {
    color: #141414;

    * {
      color: #141414;
    }
  }
}

.checkbox {
  margin-left: 4px;
  @media (max-width: $tablet) {
    width: 12px !important;
  }
}

.checkbox_label {
  @media (max-width: $tablet) {
    font-size: 13px !important;
  }
}

.no_options_found {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #141414;
  opacity: 0.7;
  margin: 8px;
}

.header_checkbox_item {
  &:first-child {
    margin-top: 4px;
  }
  @media (max-width: $tablet) {
    &:first-child {
      margin-top: 2px;
    }
    height: 25px;
  }
}

.form_control {
  pointer-events: none;
  &:global(.MuiFormControlLabel-root) {
    margin: 0px;
    gap: 6px;
  }
}

.opacity_styles {
  opacity: 0.5;
}

.option_skeleton {
  margin: 8px 8px 0 8px;
  height: 21px;
  transform: none;
}

@keyframes breathe {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}
