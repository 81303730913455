@import '../../styles/vars.scss';

.container {
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 6px;
  transition: color 100ms ease-in-out, margin-right 200ms;
  &[data-reserved-place='true'] {
    margin-right: 55px;
  }
}

.tooltip {
  bottom: 5px !important;

  & > div {
    margin: 0 !important;
    padding: 8px !important;
    max-width: none;
  }
}

.placeholder {
  padding: 2px 3px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: rgb(151, 151, 151);
  cursor: pointer;

  &.not_clickable {
    cursor: unset;
  }
  &:hover:not(.not_clickable) {
    background-color: #e7eaf7;
  }
}

.input {
  padding: 2px 3px;
  border-radius: 4px;
  border: none;
  background-color: #f1f4ff;
  border: 1px solid #a2bbff;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
}

.hidden_preview {
  @extend .preview;
  width: max-content;
  white-space: pre-wrap !important;
}

.preview {
  display: inline-block;
  padding: 2px 3px;
  border-radius: 4px;
  height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: normal;
  white-space: nowrap;
  cursor: text;
  border: 1px solid transparent;

  @media (max-width: $tablet) {
    font-size: 13px;
  }

  @media (max-width: $mobile) {
    font-size: 12px;
  }

  &.not_clickable {
    cursor: unset;
  }

  &:hover:not(:focus, .not_clickable) {
    background-color: #e7eaf7;
  }
}

.actions {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(0, 110%);
  z-index: 1000;
}

.action_button {
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.08) !important;
}

.disabled_action_button {
  background-color: rgba(210, 210, 210, 0.4);
  backdrop-filter: blur(3px);
}
